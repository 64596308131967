

































































import { Component, Vue } from 'vue-property-decorator';
import CustomButton from '@/components/CustomButton.vue';
import { EButtonMode } from '@/common/enums/button.enum';
import Preferences from '@/components/Preferences.vue';
import CookieHelper from '@/helpers/cookie-helper';
import { ICookie, ITexts } from '@/common/interfaces/index.interface';
import DomEventHelper from '@/helpers/dom-event-helper';
import Headline from '@/components/Headline.vue';
import texts from '../../configs/texts';
import cookies, { essentialCookiesCategoryName } from '../../configs/cookie-list';

@Component({
  components: { CustomButton, Headline, Preferences },
  name: `CookieNotice`,
})
export default class CookieNotice extends Vue {
  private acceptedCookies: string[] = [];
  protected cookies: ICookie[] = JSON.parse(JSON.stringify(cookies));
  protected EButtonMode = EButtonMode;
  protected showCookieNotice = true;
  protected showPreferences = false;
  protected texts: ITexts = texts;

  private async mounted(): Promise<void> {
    if(await CookieHelper.getCcnInteracted() && await CookieHelper.checkVersion()) {
      this.hideCookieNotice();
    } else {
      document.body.classList.add(`ccn-overflow-hidden`);
    }
  }

  protected acceptAll(): void {
    this.setNecessaryCookies();
    this.setAllOptionalCookies();
    CookieHelper.setCcnInteracted(this.acceptedCookies).then(() => this.hideCookieNotice());
  }

  protected acceptSelection(): void {
    this.setNecessaryCookies();
    this.setSelectedOptionalCookies();
    CookieHelper.setCcnInteracted(this.acceptedCookies).then(() => this.hideCookieNotice());
  }

  private hideCookieNotice(): void {
    this.showCookieNotice = false;
    this.showPreferences = false;
    document.body.classList.remove(`ccn-overflow-hidden`);
  }

  private setAllOptionalCookies(): void {
    const optionalCookies = this.cookies.filter(cookie => cookie.name !== essentialCookiesCategoryName);
    if(optionalCookies.length > 0) {
      for(const object of optionalCookies) {
        for(const cookie of object.cookies) {
          DomEventHelper.fireCookieSetEvent({ name: cookie.name });
          this.acceptedCookies.push(cookie.name);
        }
      }
    }
  }

  private setSelectedOptionalCookies(): void {
    const optionalCookies = this.cookies.filter(cookie => cookie.name !== essentialCookiesCategoryName);
    if(optionalCookies.length > 0) {
      for(const object of optionalCookies) {
        for(const cookie of object.cookies) {
          if(cookie.active) {
            DomEventHelper.fireCookieSetEvent({ name: cookie.name });
            this.acceptedCookies.push(cookie.name);
          }
        }
      }
    }
  }

  private setNecessaryCookies(): void {
    const necessaryCookies = this.cookies.find(cookie => cookie.name === essentialCookiesCategoryName);
    if(necessaryCookies) {
      for(const cookie of necessaryCookies.cookies) {
        if(cookie.name === `ccn-interacted`) {
          // no need to fire an event for a cookie the notice itself sets
        } else {
          DomEventHelper.fireCookieSetEvent({ name: cookie.name });
          this.acceptedCookies.push(cookie.name);
        }
      }
    }
  }

  protected showPreferencesOverlay(): void {
    this.showPreferences = true;
  }

  private updateAllCookiesOfCategory(
    active: boolean,
    indexOfCategory: number,
  ): void {
    for(const cookie of this.cookies[indexOfCategory].cookies) {
      cookie.active = active;
    }
  }

  private updateCategory(category: string): void {
    const cookieTypeIndex = this.cookies.findIndex(cookie => cookie.name === category);
    if(cookieTypeIndex > -1) {
      const activeCookies = this.cookies[cookieTypeIndex].cookies.filter(cookie => cookie.active);
      if(activeCookies.length > 0) {
        if(activeCookies.length === this.cookies[cookieTypeIndex].cookies.length) {
          this.cookies[cookieTypeIndex].active = true;
          this.cookies[cookieTypeIndex].indeterminate = false;
        } else {
          this.cookies[cookieTypeIndex].active = false;
          this.cookies[cookieTypeIndex].indeterminate = true;
        }
      } else {
        this.cookies[cookieTypeIndex].active = false;
        this.cookies[cookieTypeIndex].indeterminate = false;
      }
    }
  }

  /* eslint-disable no-param-reassign */
  protected updateCategoryActive(event: { active: boolean; cookies: ICookie }): void {
    event.cookies.active = event.active;
    event.cookies.indeterminate = false;
    const cookieTypeIndex = this.cookies.findIndex(cookie => cookie.name === event.cookies.name);
    this.cookies.splice(cookieTypeIndex, 1, event.cookies);
    this.updateAllCookiesOfCategory(event.active, cookieTypeIndex);
  }
  /* eslint-enable no-param-reassign */

  protected updateSingleCookieActive(event: { active: boolean; cookieCategory: string; cookieName: string }): void {
    const cookieTypeIndex = this.cookies.findIndex(cookie => cookie.name === event.cookieCategory);
    const cookieObject = this.cookies[cookieTypeIndex].cookies.find(cookie => cookie.name === event.cookieName);
    const cookieObjectIndex = this.cookies[cookieTypeIndex].cookies.findIndex(cookie => cookie.name === event.cookieName);
    if(cookieObject && cookieObjectIndex > -1) {
      cookieObject.active = event.active;
      this.cookies[cookieTypeIndex].cookies.splice(cookieObjectIndex, 1, cookieObject);
    }
    this.updateCategory(event.cookieCategory);
  }
}
