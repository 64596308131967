















import { Component, Prop, Vue } from 'vue-property-decorator';
import ValidationHelper from '@/helpers/validation-helper';
import { ETextSize } from '@/common/enums/index.enum';

@Component({
  name: `Headline`,
})
export default class Headline extends Vue {
  @Prop({ default: false, type: Boolean })
  protected border!: boolean;

  @Prop({ default: false, type: Boolean })
  protected clickable!: boolean;

  @Prop({ default: false, type: Boolean })
  protected noSpacing!: boolean;

  @Prop({
    default: ETextSize.LG,
    validator: val => ValidationHelper.isEnum(val, ETextSize),
  })
  protected size!: ETextSize;

  @Prop({ default: false, type: Boolean })
  protected uppercase!: boolean;

  @Prop({ required: true, type: String })
  protected text!: string;
}
