

























import { Component, Prop, Vue } from 'vue-property-decorator';
import Headline from '@/components/Headline.vue';
import CustomToggle from '@/components/CustomToggle.vue';
import { ETextSize, EToggleSize } from '@/common/enums/index.enum';
import ValidationHelper from '@/helpers/validation-helper';

@Component({
  components: { CustomToggle, Headline },
  name: `HeadlineWithToggle`,
})
export default class HeadlineWithToggle extends Vue {
  @Prop({ default: false, type: Boolean })
  protected active!: boolean;

  @Prop({ default: false, type: Boolean })
  protected border!: boolean;

  @Prop({ default: false, type: Boolean })
  protected clickable!: boolean;

  @Prop({ default: false, type: Boolean })
  protected disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  protected indeterminate!: boolean;

  @Prop({ default: false, type: Boolean })
  protected noSpacing!: boolean;

  @Prop({
    default: ETextSize.LG,
    validator: val => ValidationHelper.isEnum(val, ETextSize),
  })
  protected size!: ETextSize;

  @Prop({
    default: EToggleSize.MD,
    validator: prop => ValidationHelper.isEnum(prop, EToggleSize),
  })
  protected toggleSize!: EToggleSize;

  @Prop({ required: true, type: String })
  protected text!: string;

  protected updateActive(): void {
    if(this.disabled) {
      return;
    }
    this.$emit(`update:active`, !this.active);
  }
}
