











import { Component, Prop, Vue } from 'vue-property-decorator';
import ValidationHelper from '@/helpers/validation-helper';
import { EButtonMode } from '@/common/enums/button.enum';

@Component({
  name: `CustomButton`,
})
export default class CustomButton extends Vue {
  @Prop({
    default: `default`,
    validator: val => ValidationHelper.isEnum(val, EButtonMode),
  })
  protected mode!: EButtonMode;

  @Prop({ required: true, type: String })
  protected text!: string;
}
