
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICookieListEntry, ITexts } from '@/common/interfaces/index.interface';
import CookieListEntry from '@/components/CookieListEntry.vue';

@Component({
  components: { CookieListEntry },
  name: `CookieList`,
})
export default class CookieList extends Vue {
  @Prop({ default: false, type: Boolean })
  protected disabled!: boolean;

  @Prop({ default: () => [], type: Array as () => ICookieListEntry[] })
  protected entries!: ICookieListEntry[];

  @Prop({ required: true, type: Object as () => ITexts })
  protected texts!: ITexts;
}
