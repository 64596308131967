









import { Component, Prop, Vue } from 'vue-property-decorator';
import { ETextSize } from '@/common/enums/index.enum';
import ValidationHelper from '@/helpers/validation-helper';

@Component({
  name: `AdditionalInformation`,
})
export default class AdditionalDetailsParagraph extends Vue {
  @Prop({
    default: ETextSize.MD,
    validator: prop => ValidationHelper.isEnum(prop, ETextSize),
  })
  protected size!: string;

  @Prop({ required: true, type: String })
  protected text!: string;

  @Prop({ required: true, type: String })
  protected title!: string;
}
