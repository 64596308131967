import cookies, { essentialCookiesCategoryName } from '../../configs/cookie-list';
import { AppHelper } from './app-helper';

export default class CookieHelper {
  private static getCookie(name: string): Promise<string | false> {
    const cookiePromise: Promise<string | false> = new Promise(resolve => {
      resolve(document.cookie);
    });

    return cookiePromise.then(cookieValue => {
      if(cookieValue === false || cookieValue === null) return false;

      const cookiesArray = cookieValue.split(`;`).map(e => e.trim());
      const cookieArray = cookiesArray.filter(cookie => cookie.includes(name));
      if(cookieArray.length === 1) {
        return decodeURIComponent(cookieArray[0].split(`=`)[1]);
      }
      return false;
    });
  }

  private static removeCookie(name: string): Promise<void> {
    const domain = window.location.host.search(/localhost/i) === 0 || window.location.host === ``
      ? `localhost`
      : window.location.host.replace(/^(.*?)\.(?!(\..*\.com))/, ``);
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${domain};`;

    return Promise.resolve();
  }

  // eslint-disable-next-line quotes
  private static setCookie(name: string, value: string, options?: { expires?: number; 'max-age'?: number }): Promise<void> {
    let cookie = `${name}=${encodeURIComponent(value)};path=/;domain=${this.domain};`;

    if(options) {
      // eslint-disable-next-line no-restricted-syntax
      for(const option in options) {
        if(Object.prototype.hasOwnProperty.call(options, option)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          cookie += `${option}=${options[option]};`;
        }
      }
    }

    // eslint-disable-next-line quotes
    if(!options || (options && !options.expires && !options['max-age'])) {
      cookie += `max-age=${60 * 60 * 24 * 365};expires=${new Date(Date.now() + (60 * 60 * 24 * 365 * 1000)).toUTCString()};`;
    }

    cookie += window.location.protocol !== `http:` ? `Secure;` : ``;
    cookie += `SameSite=Lax`;

    document.cookie = cookie;

    return Promise.resolve();
  }

  /**
   * TODO - grab domain detection from ringana.com and try make it universally usable
   */
  private static get domain(): string {
    return window.location.host.search(/localhost/i) === 0 || window.location.host === `` ? `localhost` : window.location.host;
  }

  public static getCcnInteracted(): Promise<string[] | boolean> {
    return this.getCookie(`ccn-interacted`).then(item => {
      if(typeof item === `string`) {
        return JSON.parse(item);
      }
      return item;
    });
  }

  public static removeCcnInteracted(): Promise<void> {
    return this.removeCookie(`ccn-interacted`);
  }

  public static async setCcnInteracted(acceptedCookies: string[]): Promise<void> {
    await this.setCookie(`ccn-interacted`, JSON.stringify(acceptedCookies));
    await this.setCookie(`ccn-version`, AppHelper.createVersionHash());
  }

  public static async checkVersion(): Promise<boolean> {
    const versionCookie = await this.getCcnVersion();
    if(!versionCookie || versionCookie !== AppHelper.createVersionHash()) {
      this.removeNonEssentialCookies();
      this.removeCcnInteracted();
      return false;
    }
    return true;
  }

  private static getAllCookieNames(): string[] {
    const cookiesArray = document.cookie.split(`; `);
    const cookieArray = cookiesArray.filter(item => {
      return item.split(`=`)[0];
    });
    return cookieArray.map(cookie => decodeURIComponent((cookie.split(`=`)[0])));
  }

  public static getCcnVersion(): Promise<string | false> {
    return this.getCookie(`ccn-version`);
  }

  public static removeNonEssentialCookies(): void {
    const essentialCookieCategory = cookies.find(el => el.name === essentialCookiesCategoryName);
    let essentialCookieNames: string[] = [];
    if(essentialCookieCategory) {
      essentialCookieNames = essentialCookieCategory.cookies.map(cookie => cookie.name);
    }
    const allCookieNames = this.getAllCookieNames();
    for(const cookieName of allCookieNames) {
      if(!essentialCookieNames.includes(cookieName)) {
        this.removeCookie(cookieName);
      }
    }
  }
}
