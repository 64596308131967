







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICookieListEntry, ITexts } from '@/common/interfaces/index.interface';
import HeadlineWithToggle from '@/components/HeadlineWithToggle.vue';
import { ETextSize, EToggleSize } from '@/common/enums/index.enum';
import ExplanationParagraph from '@/components/ExplanationParagraph.vue';
import AdditionalInformation from '@/components/AdditionalInformation.vue';

@Component({
  components: {
    AdditionalInformation,
    ExplanationParagraph,
    HeadlineWithToggle,
  },
  name: `CookieListEntry`,
})
export default class CookieListEntry extends Vue {
  @Prop({ default: false, type: Boolean })
  protected disabled!: boolean;

  @Prop({ required: true, type: Object as () => ICookieListEntry })
  protected entry!: ICookieListEntry;

  @Prop({ required: true, type: Object as () => ITexts })
  protected texts!: ITexts;

  protected ETextSize = ETextSize;
  protected EToggleSize = EToggleSize;
}
