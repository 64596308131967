export default class ValidationHelper {
  public static isEnum(value: string, enumType: { [index: string]: string }): boolean {
    let valueInObject = false;

    for(const key of Object.keys(enumType)) {
      if(enumType[key] === value) {
        valueInObject = true;
        break;
      }
    }

    return valueInObject;
  }

  public static isNumberStringOrNull(prop: number | string | null): boolean {
    return typeof prop === `number` || typeof prop === `string` || prop === null;
  }
}
