





























import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import ValidationHelper from '@/helpers/validation-helper';
import { EToggleSize } from '@/common/enums/index.enum';

@Component({
  name: `CustomToggle`,
})
export default class CustomToggle extends Vue {
  $refs!: {
    customToggleInputCheckbox: HTMLInputElement;
  };

  @Prop({ default: false, type: Boolean })
  protected active!: boolean;

  @Prop({ default: false, type: Boolean })
  protected disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  protected indeterminate!: boolean;

  @Prop({
    default: null,
    validator: prop => ValidationHelper.isNumberStringOrNull(prop),
  })
  protected labelAfter!: string;

  @Prop({
    default: null,
    validator: prop => ValidationHelper.isNumberStringOrNull(prop),
  })
  protected labelBefore!: string;

  @Prop({
    default: EToggleSize.MD,
    validator: prop => ValidationHelper.isEnum(prop, EToggleSize),
  })
  protected size!: EToggleSize;

  @Watch(`indeterminate`, { immediate: true })
  private indeterminateChanged(): void {
    if(this.$refs.customToggleInputCheckbox) {
      this.$refs.customToggleInputCheckbox.indeterminate = this.indeterminate;
    }
  }
}
