






import { Component, Vue } from 'vue-property-decorator';
import CookieNotice from '@/components/CookieNotice.vue';

@Component({
  components: { CookieNotice },
})
export default class App extends Vue {
  mounted(): void {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,consistent-return,func-names,wrap-iife
    (function () {
      if(typeof window.CustomEvent === `function`) return false;

      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      function CustomEvent(event: string, params: { bubbles: boolean; cancelable: boolean; detail: unknown }) {
        // eslint-disable-next-line no-param-reassign
        params = params || { bubbles: false, cancelable: false, detail: null };
        // eslint-disable-next-line no-var,vars-on-top
        var evt = document.createEvent(`CustomEvent`);
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      window.CustomEvent = CustomEvent;
    })();
  }
}
